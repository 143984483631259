<template>
    <span>test</span>
</template>

<script>
    export default {
        name: "Test"
    }
</script>

<style scoped>

</style>